import { Button, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { LuFlame } from "react-icons/lu";
import { Booking, BookingStatus, BookingType, Court, Hour, UserRole } from "../../api/types";
import useAuthStore from "../../store/auth";
import usePaymentStore from "../../store/payment";
import { removeFocus } from "../../utils/ui";
import BookingEditModal from "./booking-edit-modal";
import BookingModal from "./booking-modal";
import { CellColor } from "./types";

type BookingCellProps = {
  date: Date;
  hour: Hour;
  court: Court;
  booking?: Booking;
  onBookingCompleted?: () => void;
  onBookingEditCompleted?: () => void;
};

const BookingCell = ({
  date,
  hour,
  court,
  booking,
  onBookingCompleted = () => null,
  onBookingEditCompleted = () => null,
}: BookingCellProps) => {
  const [user] = useAuthStore(state => [state.user]);
  const [bookingsToPay] = usePaymentStore(state => [state.bookingsToPay]);

  const {
    isOpen: isBookingModalOpen,
    onOpen: onBookingModalOpen,
    onClose: onBookingModalClose,
  } = useDisclosure();

  const {
    isOpen: isBookingEditModalOpen,
    onOpen: onBookingEditModalOpen,
    onClose: onBookingEditModalClose,
  } = useDisclosure();

  const handleCellClick = () => {
    removeFocus();
    if (booking) {
      onBookingEditModalOpen();
    } else {
      onBookingModalOpen();
    }
  };

  const getBackgroundColor = () => {
    // Only admin users can see the colors
    if (!booking || user?.role !== UserRole.ADMIN) {
      return CellColor.DEFAULT;
    }

    if (bookingsToPay.find(b => b.id === booking.id)) {
      return CellColor.TO_PAY;
    }
    if (booking.type === BookingType.SERVICE) {
      return CellColor.SERVICE_HOUR;
    }
    if (booking.status === BookingStatus.PAID) {
      return CellColor.PAID;
    }
    if (booking.type === BookingType.SOCIAL_CHAMPIONSHIP) {
      return CellColor.SOCIAL_CHAMPIONSHIP;
    }
    if (booking.status === BookingStatus.BOOKED || booking.status === BookingStatus.NOT_PAID) {
      return CellColor.BOOKED;
    }
    if (booking.status === BookingStatus.NOT_PLAYED) {
      return CellColor.NOT_PLAYED;
    }

    return CellColor.DEFAULT;
  };

  const isStriped = () => {
    // Only admin users can see the colors
    if (!booking || user?.role !== UserRole.ADMIN) {
      return false;
    }

    if (
      booking?.status === BookingStatus.NOT_PLAYED ||
      booking?.status === BookingStatus.NOT_PAID
    ) {
      return true;
    }

    return false;
  };

  const showHeatingIcon = () => {
    // Only admin users can see the colors
    if (!booking || user?.role !== UserRole.ADMIN) {
      return false;
    }

    return booking.is_heated;
  };

  /*
  Show only the booking name in case:
   - user is not logged in
   - booking does not belong to the user and user is not an admin
  */
  if (!user || (booking && booking.user_id !== user.id && user.role !== UserRole.ADMIN)) {
    return (
      <>
        <Text
          width="100%"
          height="30px"
          p="2"
          display="flex"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          fontSize="md"
        >
          {booking?.username}
        </Text>
      </>
    );
  }

  /*
  Allow actions if:
  - user is logged in and the booking belongs to the user
  - user is an admin
  */
  return (
    <>
      <Tooltip
        placement="top"
        label={`${booking?.user_name} ${booking?.user_surname}`}
        isDisabled={!booking || !(user.role === UserRole.ADMIN)} // show tooltip only for admin users
      >
        <Button
          width="100%"
          height="30px"
          p="2"
          borderRadius="0"
          fontSize="md"
          fontWeight="normal"
          colorScheme="gray"
          variant="ghost"
          size="xs"
          _hover={{ boxShadow: "lg", cursor: "pointer", bg: "gray.100" }}
          onClick={handleCellClick}
          backgroundColor={getBackgroundColor()}
          className={isStriped() ? "striped" : ""}
        >
          {showHeatingIcon() && (
            <LuFlame size={20} fill="currentColor" style={{ position: "absolute", left: "8px" }} />
          )}
          {booking?.username}
        </Button>
      </Tooltip>

      {/* TODO: move modals to table component to avoid render them 100 times  */}
      {/* Booking modal */}
      <BookingModal
        isOpen={isBookingModalOpen}
        onClose={onBookingModalClose}
        date={date}
        hour={hour}
        court={court}
        onBookingCompleted={onBookingCompleted}
      />

      {/* Edit Booking modal */}
      {booking && (
        <BookingEditModal
          isOpen={isBookingEditModalOpen}
          onClose={onBookingEditModalClose}
          booking={booking}
          onBookingEditCompleted={onBookingEditCompleted}
        />
      )}
    </>
  );
};

export default BookingCell;
