import { useEffect } from "react";
import { HIDE_ANNOUNCEMENT_KEY } from "../constants";

const useBeforeUnload = (): void => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem(HIDE_ANNOUNCEMENT_KEY);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
};

export default useBeforeUnload;
