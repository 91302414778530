import create, { GetState, SetState } from "zustand";
import { Setting, SettingName } from "../api/types";

type SettingStore = {
  settings: Setting[];
  setSettings: (settings: Setting[]) => void;
  getSetting: (name: SettingName) => string | undefined;
};

const useSettingsStore = create<SettingStore>(
  (set: SetState<SettingStore>, get: GetState<SettingStore>) => ({
    settings: [],
    setSettings: (settings: Setting[]) => set(state => ({ settings })),
    getSetting: (name: SettingName) => {
      const setting = get().settings.find(s => s.name === name);
      return setting?.value;
    },
  }),
);

export default useSettingsStore;
