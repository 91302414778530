import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import { ReactElement, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import api from "../../../api";
import ApiError from "../../../api/errors";
import { HttpError, SettingName, SignupRequest } from "../../../api/types";
import useValidatePassword from "../../../hooks/useValidatePassword";
import useSettingsStore from "../../../store/settings";
import PasswordInput from "../../ui/password-input/password-input";

type SignupFormData = {
  name: string;
  surname: string;
  username: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  acceptTermsAndConditions: boolean;
};

type FormErrors = {
  userAlreadyExists: boolean;
  signupFailed: boolean;
};

type SignupFormProps = {
  onCompleted: (user_id: number) => void;
};

const SignupForm = ({ onCompleted }: SignupFormProps): ReactElement => {
  const { t } = useTranslation();
  const [getSetting] = useSettingsStore(state => [state.getSetting]);

  const { register, handleSubmit } = useForm<SignupFormData>();
  const { isPasswordValid, passwordError, resetPasswordError } = useValidatePassword();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const ERRORS_INITIAL_VALUE: FormErrors = {
    userAlreadyExists: false,
    signupFailed: false,
  };

  const [errors, setErrors] = useState(ERRORS_INITIAL_VALUE);

  const resetErrors = () => {
    resetPasswordError();
    setErrors(ERRORS_INITIAL_VALUE);
  };

  const hasErrors = (): boolean => Object.values(errors).some(value => value === true);

  const getErrorMessage = () => {
    const errorMessages = {
      userAlreadyExists: t("signup.errors.user_already_exists"),
      signupFailed: t("signup.errors.signup_failed"),
    };

    const errorKey = Object.keys(errors).find(key => errors[key as keyof FormErrors]);
    return errorKey ? errorMessages[errorKey as keyof FormErrors] : null;
  };

  const handleSignupError = (error: HttpError) => {
    switch (error.data.detail) {
      case ApiError.SIGNUP_USER_ALREADY_EXISTS: {
        setErrors(prev => ({ ...prev, userAlreadyExists: true }));
        break;
      }
      default: {
        setErrors(prev => ({ ...prev, signupFailed: true }));
        break;
      }
    }
  };

  const onSubmit = async (data: SignupFormData) => {
    // validate password
    if (!isPasswordValid(data.password, data.confirmPassword)) {
      return;
    }

    // signup user
    const request: SignupRequest = { ...data, captcha_token: captchaToken ?? "" };
    const response = await api.signup(request);
    if (api.error) {
      handleSignupError(api.error);
      return;
    }
    if (!response) {
      console.error("error during signup");
      return;
    }

    // show email confirmation page
    onCompleted(response.user_id);
  };

  return (
    <form id="signup-form" onSubmit={handleSubmit(onSubmit)} onChange={resetErrors}>
      <Box p={8} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white">
        <VStack spacing={4} align="flex-start">
          <Heading as="h1" size="lg" mb={4}>
            {t("signup.signup")}
          </Heading>

          <FormControl isRequired>
            <FormLabel>{t("signup.name")}</FormLabel>
            <Input
              placeholder={t("signup.placeholder.name")}
              id="name"
              {...register("name", { required: "name is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.surname")}</FormLabel>
            <Input
              placeholder={t("signup.placeholder.surname")}
              id="surname"
              {...register("surname", { required: "surname is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.username")}</FormLabel>
            <Input
              placeholder={t("signup.placeholder.username")}
              id="username"
              {...register("username", { required: "username is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.email")}</FormLabel>
            <Input
              type="email"
              placeholder={t("signup.placeholder.email")}
              id="email"
              {...register("email", { required: "email is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.phone")}</FormLabel>
            <Input
              type="tel"
              placeholder={t("signup.placeholder.phone")}
              id="phone"
              {...register("phone", { required: "phone is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.password")}</FormLabel>
            <PasswordInput
              placeholder={t("signup.placeholder.password")}
              id="password"
              {...register("password", { required: "password is required" })}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>{t("signup.confirm_password")}</FormLabel>
            <PasswordInput
              placeholder={t("signup.placeholder.confirm_password")}
              id="confirmPassword"
              {...register("confirmPassword", {
                required: "confirmPassword is required",
              })}
            />
          </FormControl>

          <FormControl isRequired>
            <Checkbox
              id="acceptTermsAndConditions"
              {...register("acceptTermsAndConditions", {
                required: "acceptTermsAndConditions is required",
              })}
            >
              {t("signup.privacy_policy_text")}&nbsp;
              <a href="https://www.iubenda.com/privacy-policy/43646963">
                {t("signup.privacy_policy_link")}
              </a>
            </Checkbox>
          </FormControl>

          <Box alignSelf="center" mt={4}>
            <ReCAPTCHA
              sitekey={getSetting(SettingName.CAPTCHA_SITE_KEY) ?? ""}
              onChange={value => setCaptchaToken(value)}
            />
          </Box>

          {/* Errors */}
          {hasErrors() && (
            <Alert status="error" mb="0.5rem">
              <AlertIcon />
              <AlertDescription>{getErrorMessage()}</AlertDescription>
            </Alert>
          )}

          {passwordError && (
            <Alert status="error" mb="0.5rem">
              <AlertIcon />
              <AlertDescription>{passwordError.message}</AlertDescription>
            </Alert>
          )}

          <Button
            colorScheme="green"
            size="lg"
            width="full"
            mt={2}
            alignSelf="center"
            form="signup-form"
            type="submit"
          >
            {t("signup.signup_button")}
          </Button>
        </VStack>
      </Box>
    </form>
  );
};

export default SignupForm;
