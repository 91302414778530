import { useEffect } from "react";
import api from "../api";
import useSettingsStore from "../store/settings";

/**
 * Custom hook to fetch settings from backend.
 * @returns {void}
 */
const useInitSettings = (): void => {
  const [setSettings] = useSettingsStore(state => [state.setSettings]);

  const fetchSettings = async () => {
    const settings = await api.getSettings();
    if (!settings) {
      return;
    }

    setSettings(settings);
  };

  useEffect(() => {
    fetchSettings();
  }, []);
};

export default useInitSettings;
