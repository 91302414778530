import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LuCircle, LuFlame, LuPencil } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { Booking, BookingStatus, BookingType, Permission, UserRole } from "../../api/types";
import useShowToast from "../../hooks/useShowToast";
import useAuthStore from "../../store/auth";
import usePaymentStore from "../../store/payment";
import { CellColor } from "./types";

enum EditAction {
  CANCEL = "cancel",
  EDIT = "edit",
}

type BookingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  booking: Booking;
  onBookingEditCompleted?: () => void;
};

const BookingEditModal = ({
  isOpen,
  onClose,
  booking,
  onBookingEditCompleted = () => null,
}: BookingModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user] = useAuthStore(state => [state.user]);
  const [bookingsToPay, setBookingsToPay] = usePaymentStore(state => [
    state.bookingsToPay,
    state.setBookingsToPay,
  ]);
  const { successToast, errorToast } = useShowToast();
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [showRenameInput, setShowRenameInput] = useState(false);
  const [renameInputValue, setRenameInputValue] = useState(booking.username);

  const showSuccessToast = (action: EditAction) => {
    successToast({
      title: t(`booking.modal_edit.${action}.success_title`),
      description: t(`booking.modal_edit.${action}.success_message`),
    });
  };

  const showErrorToast = (action: EditAction, errorCode: any) => {
    const description = t(`booking.modal_edit.${action}.error_message.${errorCode.detail}`, {
      defaultValue: t(`booking.modal_edit.${action}.error_message.default`),
    });

    errorToast({
      title: t(`booking.modal_edit.${action}.error_title`),
      description,
    });
  };

  const cancelBooking = async () => {
    setIsCancelLoading(true);

    await api.cancelBooking(booking.id);
    if (api.error) {
      console.log("Error canceling booking", api.error);
      setIsCancelLoading(false);
      onClose();
      showErrorToast(EditAction.CANCEL, api.error.data);
      return;
    }

    // Close modal
    setIsCancelLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.CANCEL);
  };

  const payBooking = async () => {
    bookingsToPay.push(booking);
    setBookingsToPay(bookingsToPay);
    onClose();
  };

  const setNotPlayed = async () => {
    setIsEditLoading(true);
    await api.updateBooking(booking.id, { status: BookingStatus.NOT_PLAYED });
    if (api.error) {
      console.log("Error updating booking", api.error);
      setIsEditLoading(false);
      onClose();
      showErrorToast(EditAction.EDIT, api.error.data);
      return;
    }

    // Close modal
    setIsEditLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.EDIT);
  };

  const setNotPaid = async () => {
    setIsEditLoading(true);
    await api.updateBooking(booking.id, { status: BookingStatus.NOT_PAID });
    if (api.error) {
      console.log("Error updating booking", api.error);
      setIsEditLoading(false);
      onClose();
      showErrorToast(EditAction.EDIT, api.error.data);
      return;
    }

    // Close modal
    setIsEditLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.EDIT);
  };

  const setSocialChampionship = async () => {
    setIsEditLoading(true);
    await api.updateBooking(booking.id, { type: BookingType.SOCIAL_CHAMPIONSHIP });
    if (api.error) {
      console.log("Error updating booking", api.error);
      setIsEditLoading(false);
      onClose();
      showErrorToast(EditAction.EDIT, api.error.data);
      return;
    }

    // Close modal
    setIsEditLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.EDIT);
  };

  const setIsHeated = async () => {
    setIsEditLoading(true);
    await api.updateBooking(booking.id, { is_heated: !booking.is_heated });
    if (api.error) {
      console.log("Error updating booking", api.error);
      setIsEditLoading(false);
      onClose();
      showErrorToast(EditAction.EDIT, api.error.data);
      return;
    }

    // Close modal
    setIsEditLoading(false);
    onClose();
    onBookingEditCompleted();
    showSuccessToast(EditAction.EDIT);
  };

  const renameBooking = async () => {
    if (booking.username === renameInputValue) {
      setShowRenameInput(false);
      return;
    }

    await api.renameBooking(booking.id, { username: renameInputValue });
    if (api.error) {
      console.log("Error updating booking", api.error);
      showErrorToast(EditAction.EDIT, api.error.data);
      return;
    }

    onBookingEditCompleted();
    showSuccessToast(EditAction.EDIT);
    setShowRenameInput(false);
  };

  const printReceipt = async () => {
    const receiptId = booking.receipt_id;
    navigate(`/receipt/${receiptId}`);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="500px">
        {/* Modal title */}
        <ModalHeader>{t("booking.modal_edit.edit_booking")}</ModalHeader>

        <Divider />

        {/* Modal Body */}
        <ModalBody>
          <Text mb={4}>{t("booking.modal_edit.edit_message")}</Text>

          {/* Common fields */}
          <Flex align="center">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.name")}:
            </Text>
            <Box mx={2} />
            {showRenameInput ? (
              <InputGroup size="sm" display="inline-flex">
                <Input
                  placeholder="Username"
                  value={renameInputValue}
                  onChange={e => setRenameInputValue(e.target.value)}
                />
                <InputRightElement>
                  <CheckIcon onClick={renameBooking} cursor="pointer" />
                </InputRightElement>
              </InputGroup>
            ) : (
              <Text as="span">
                {booking.user_name} {booking.user_surname} ({booking.username})
              </Text>
            )}
          </Flex>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.court")}:
            </Text>
            {"  "}
            <Text as="span">{booking.court_label}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.date")}:
            </Text>
            {"      "}
            <Text as="span">{format(booking.date, "dd/MM/yyyy")}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.hour")}:
            </Text>
            {"        "}
            <Text as="span">{booking.hour_label}</Text>
          </Text>

          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal.type")}:
            </Text>
            {"       "}
            <Text as="span">{t(`booking.modal.${booking.type}`)}</Text>
          </Text>
          <Text className="preserve-white-space">
            <Text as="span" fontWeight="bold">
              {t("booking.modal_edit.status.title")}:
            </Text>
            {"     "}
            <Text as="span">
              {t(`booking.modal_edit.status.${booking.status}`, {
                defaultValue: t("booking.modal_edit.status.unknown"),
              })}
            </Text>
            <Text className="preserve-white-space">
              <Text as="span" fontWeight="bold">
                {booking.is_heated
                  ? t("booking.modal_edit.is_heated")
                  : t("booking.modal_edit.is_not_heated")}
              </Text>
            </Text>
          </Text>
        </ModalBody>

        <Divider />

        {/* Modal Footer with buttons */}
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            {t("booking.modal_edit.btn_cancel")}
          </Button>

          {/* user only buttons */}
          {user?.role !== UserRole.ADMIN && (
            <>
              {/* Cancel */}
              {booking.status !== BookingStatus.PAID && (
                <Button
                  colorScheme="orange"
                  ml={3}
                  onClick={cancelBooking}
                  isLoading={isCancelLoading}
                >
                  {t("booking.modal_edit.cancel.btn_title")}
                </Button>
              )}
            </>
          )}

          {/* admin only buttons */}
          {user?.role === UserRole.ADMIN && (
            <>
              {/* Edit */}
              {user?.role === UserRole.ADMIN && booking.status !== BookingStatus.PAID && (
                <Menu>
                  <MenuButton as={Button} colorScheme="blue" isLoading={isEditLoading}>
                    {t("booking.modal_edit.edit.btn_title")} <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={setNotPlayed}>
                      <LuCircle size={14} fill="currentColor" color={CellColor.NOT_PLAYED} />
                      <Text ml={2}>{t("booking.modal_edit.edit.not_played")}</Text>
                    </MenuItem>
                    <MenuItem onClick={setNotPaid}>
                      <LuCircle size={14} fill="currentColor" color={CellColor.NOT_PAID} />
                      <Text ml={2}>{t("booking.modal_edit.edit.not_paid")}</Text>
                    </MenuItem>
                    <MenuItem onClick={setSocialChampionship}>
                      <LuCircle
                        size={14}
                        fill="currentColor"
                        color={CellColor.SOCIAL_CHAMPIONSHIP}
                      />
                      <Text ml={2}>{t("booking.modal_edit.edit.social_championship")}</Text>
                    </MenuItem>
                    <MenuDivider />
                    <MenuItem onClick={setIsHeated}>
                      <LuFlame />
                      <Text ml={2}>
                        {booking.is_heated
                          ? t("booking.modal_edit.edit.remove_heating")
                          : t("booking.modal_edit.edit.add_heating")}
                      </Text>
                    </MenuItem>
                    {user.permissions.includes(Permission.RENAME_BOOKING) && (
                      <MenuItem onClick={() => setShowRenameInput(true)}>
                        <LuPencil />
                        <Text ml={2}>{t("booking.modal_edit.edit.change_name")}</Text>
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}

              {/* Cancel */}
              {booking.status !== BookingStatus.PAID && (
                <Button
                  colorScheme="orange"
                  ml={3}
                  onClick={cancelBooking}
                  isLoading={isCancelLoading}
                >
                  {t("booking.modal_edit.cancel.btn_title")}
                </Button>
              )}

              {/* Pay */}
              {booking.status !== BookingStatus.PAID && (
                <Button
                  colorScheme="green"
                  ml={2}
                  onClick={payBooking}
                  isDisabled={bookingsToPay.includes(booking)}
                >
                  {t("booking.modal_edit.pay.btn_title")}
                </Button>
              )}

              {/* Print again receipt */}
              {booking.status === BookingStatus.PAID && (
                <Button colorScheme="blue" ml={2} onClick={printReceipt}>
                  {t("booking.modal_edit.print_receipt.btn_title")}
                </Button>
              )}
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BookingEditModal;
