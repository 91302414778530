import useBeforeUnload from "./useBeforeUnload";
import useInitSettings from "./useInitSettings";
import useInitUser from "./useInitUser";

const useInitApp = (): void => {
  useInitUser();
  useInitSettings();
  useBeforeUnload();
};

export default useInitApp;
