import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { SettingName } from "../../api/types";
import { HIDE_ANNOUNCEMENT_KEY } from "../../constants";
import useSettingsStore from "../../store/settings";

const AnnouncementBanner = () => {
  const [getSetting] = useSettingsStore(state => [state.getSetting]);
  const { isOpen: isVisible, onClose: closeAlert } = useDisclosure({ defaultIsOpen: true });
  const announcementText = getSetting(SettingName.ANNOUNCEMENT_TEXT);

  if (localStorage.getItem(HIDE_ANNOUNCEMENT_KEY)) {
    return null;
  }

  const onClose = () => {
    localStorage.setItem(HIDE_ANNOUNCEMENT_KEY, "true");
    closeAlert();
  };

  return isVisible ? (
    <Box p={4} pb={0} pt={2}>
      <Alert status="info" position="relative">
        <AlertIcon />
        <Box>
          <AlertDescription>{announcementText}</AlertDescription>
        </Box>
        <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
      </Alert>
    </Box>
  ) : null;
};

export default AnnouncementBanner;
