import { Court } from "../../api/types";

export type Filters = {
  court: Court;
  week: Week;
};

export type Week = {
  id: number;
  start: Date;
  end: Date;
};

export enum CellColor {
  DEFAULT = "white",
  PAID = "green.300",
  BOOKED = "red.300",
  TO_PAY = "orange.300",
  SERVICE_HOUR = "yellow.300",

  // TODO: map cell colors in cell components
  NOT_PLAYED = "#A0AEC0", // gray.400
  NOT_PAID = "#FC8181", // red.300
  SOCIAL_CHAMPIONSHIP = "#B7791F", // yellow.600
}
